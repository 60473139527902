import { useEffect } from 'react'
import { Link } from 'react-router'
import styled from '@emotion/styled'
import { Fab } from '@mui/material'
import { Popup } from 'src/ui-kit/popup/Popup'
import IconChevronDown from 'src/assets/images/down-chevron.svg'
import { prettifyHunterParkingPhotoTaskName } from 'src/utils/hunterParkingPhotos/hunterParkingPhotosUtils'
import { formatUnixDate } from 'src/utils/time/timeUtils'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import {
    getHunterParkingPhotoByTaskId,
    showNextHunterParkingPhoto,
    showPreviousHunterParkingPhoto,
    selectHunterParkingPhotoByTaskId,
    selectIsFirstHunterParkingPhoto,
    selectIsLastHunterParkingPhoto,
} from 'src/redux/hunterParkingPhotos'
import * as taskUtils from 'src/utils/tasks/tasksUtils'
import { ColumnContainer } from 'src/ui-kit/containers/ColumnContainer'
import { type Task } from 'src/api/fm/tasks/tasks.model'
import { useGlobalHotkeys } from 'src/components/app/utils/hooks/useGlobalHotkeys'
import * as colors from 'src/ui-kit/colors/colors'

interface Props {
    task: Task
    close: () => void
}

export const HunterParkingPhotoModal = (props: Props) => {
    const { task, close } = props
    const { id, type, auditLog, dropState } = task
    const formattedTaskType = prettifyHunterParkingPhotoTaskName(type)
    const dropper = auditLog && taskUtils.getTaskDropper(auditLog)
    const dropTimeFormatted = dropState?.time ? formatUnixDate(Number(dropState.time)) : undefined
    const photoUrl = useAppSelector(selectHunterParkingPhotoByTaskId(id))
    const isFirstPhoto = useAppSelector(selectIsFirstHunterParkingPhoto)
    const isLastPhoto = useAppSelector(selectIsLastHunterParkingPhoto)
    const dispatch = useAppDispatch()

    useEffect(() => {
        if (!photoUrl) {
            dispatch(getHunterParkingPhotoByTaskId(id))
        }
    }, [photoUrl, id, dispatch])

    const dispatchShowPreviousPhoto = () => {
        if (!isFirstPhoto) {
            dispatch(showPreviousHunterParkingPhoto())
        }
    }

    const dispatchShowNextPhoto = () => {
        if (!isLastPhoto) {
            dispatch(showNextHunterParkingPhoto())
        }
    }

    useGlobalHotkeys([
        {
            key: 'ArrowLeft',
            fn: e => {
                e.preventDefault()
                dispatchShowPreviousPhoto()
            },
        },
        {
            key: 'ArrowRight',
            fn: e => {
                e.preventDefault()
                dispatchShowNextPhoto()
            },
        },
        {
            key: 'Escape',
            fn: e => {
                e.preventDefault()
                close()
            },
        },
    ])

    return (
        <Popup
            title='Parking Photo'
            close={close}
            removeCancel={false}
            cancelText='Close'
            maxWidth={'428px'}
            dataTestId={'hunter-parking-photo-modal'}
        >
            <ActionButtons
                dispatchShowPreviousPhoto={dispatchShowPreviousPhoto}
                dispatchShowNextPhoto={dispatchShowNextPhoto}
                isFirstPhoto={isFirstPhoto}
                isLastPhoto={isLastPhoto}
            />

            <ColumnContainer>
                <span>
                    <LabelStyled>Task:</LabelStyled> {formattedTaskType} {dropTimeFormatted}
                </span>
                {dropper && (
                    <span>
                        <LabelStyled>User:</LabelStyled> <Link to={`/hunters/${dropper.id}`}>{dropper.email}</Link>
                    </span>
                )}
                <img src={photoUrl} alt='' data-testid='hunter-parking-photo-img' />
            </ColumnContainer>
        </Popup>
    )
}

interface ActionButtonsProps {
    dispatchShowPreviousPhoto: () => void
    dispatchShowNextPhoto: () => void
    isFirstPhoto: boolean
    isLastPhoto: boolean
}

const ActionButtons = ({
    dispatchShowPreviousPhoto,
    dispatchShowNextPhoto,
    isFirstPhoto,
    isLastPhoto,
}: ActionButtonsProps) => {
    return (
        <>
            {!isFirstPhoto && (
                <Fab
                    aria-label='previous'
                    onClick={dispatchShowPreviousPhoto}
                    style={{
                        backgroundColor: colors.white,
                        position: 'absolute',
                        top: 'calc(50% - 28px)',
                        left: 'calc(50% - 283px)',
                    }}
                >
                    <IconPrevious src={IconChevronDown} />
                </Fab>
            )}

            {!isLastPhoto && (
                <Fab
                    aria-label='next'
                    onClick={dispatchShowNextPhoto}
                    style={{
                        backgroundColor: colors.white,
                        position: 'absolute',
                        top: 'calc(50% - 28px)',
                        right: 'calc(50% - 283px)',
                    }}
                >
                    <IconNext src={IconChevronDown} />
                </Fab>
            )}
        </>
    )
}

const IconPrevious = styled.img`
    height: 24px;
    width: 24px;
    transform: rotate(90deg);
`

const IconNext = styled.img`
    height: 24px;
    width: 24px;
    transform: rotate(270deg);
`

const LabelStyled = styled.span`
    font-weight: 600;
    display: inline-block;
    width: 50px;
`
