import {
    TOGGLE_END_RIDE_MODAL,
    GET_CURRENT_RIDER_FULL_PROFILE,
    GET_ACCOUNT_SHARING_INFO,
    GET_RIDE_PARKING_PHOTO,
    GET_DISCOUNT_GROUPS,
    GET_RIDER_DISCOUNT,
    SET_CURRENT_RIDER,
    SET_ACCOUNT_SHARING_INFO,
    SET_DISCOUNT_GROUPS,
    SET_RIDER_DISCOUNT,
    GIVE_RIDER_CREDITS,
    GIVE_RIDER_DISCOUNTS,
    FORGIVE_RIDER_DEBT,
    EDIT_RIDER_DISCOUNT,
    SET_RIDER_RIDE_HISTORY,
    SET_RIDE_PARKING_PHOTO,
    DELETE_RIDER,
    SUBMIT_END_RIDE,
    SET_ACTIVE_RIDE_RIDER,
    SET_GUEST_RIDES,
    GET_RIDER_RIDE_HISTORY,
    RESET_RIDER_RIDE_HISTORY,
    SET_RIDER_RIDE_HISTORY_DATE_FILTER,
    BLOCK_RIDER,
    GET_DRIVERS_LICENSE_STATUS,
    SET_DRIVERS_LICENSE_STATUS,
    DELETE_DRIVERS_LICENSE,
    APPROVE_DRIVERS_LICENSE_DOCUMENT,
    APPROVE_DRIVERS_LICENSE_FACE_CHECK,
    GET_RIDER_VOI_PASS,
    SET_RIDER_VOI_PASS,
    EXPIRE_RIDER_VOI_PASS,
    ABANDON_CONTRACT,
    CANCEL_AND_REFUND_CONTRACT,
    STOP_CONTRACT_RENEWAL,
    GET_RIDE_HISTORY_ITEM,
    SET_CURRENT_RIDE_HISTORY_ITEM,
    GET_RIDER_VOUCHER_DETAILS,
    SET_RIDER_VOUCHER_DETAILS,
    DELETE_RIDER_VOUCHERS,
    UNBLOCK_RIDER_DEVICE_SWITCH,
    UPDATE_DATE_OF_BIRTH,
    DELETE_RIDER_PHONE,
    GET_RIDER_FINE_HISTORY,
    SET_RIDER_FINE_HISTORY,
    RESET_RIDER_FINE_HISTORY,
    REQUEST_ID_CHECK,
    type ParkingPhoto,
    type GetCurrentRiderFullProfile,
    type GetAccountSharingInfo,
    type GetRiderDiscount,
    type GetRiderVoiPass,
    type GiveRiderCredits,
    type GiveRiderDiscounts,
    type BlockRider,
    type GetRiderRideHistory,
    type ForgiveRiderDebt,
    type EditRiderDiscount,
    type GetRideHistoryItem,
    type GetRideParkingPhoto,
    type DeleteRider,
    type GetDriversLicenseStatus,
    type DeleteDriversLicense,
    type ApproveDriversLicenseDocument,
    type ApproveDriversLicenseFaceCheck,
    type ExpireRiderVoiPass,
    type AbandonContract,
    type CancelAndRefundContract,
    type StopContractRenewal,
    type RiderDiscount,
    type RiderDiscountGroup,
    type UpdateDateOfBirth,
    type GetRiderVoucherDetails,
    type DeleteRiderPhone,
    type GetRiderFineHistory,
    type SetRiderFineHistory,
    type ResetRiderFineHistory,
    type GetRiderPaymentDetails,
    SET_RIDER_PAYMENT_DETAILS,
    SET_RIDER_RIDE_HISTORY_SOURCE_FILTER,
    GET_RIDER_PAYMENT_DETAILS,
    SET_DELETE_USER_FAILED,
    type DeleteUserFailed,
    type RequestIDCheck,
    type ToggleEndRideModal,
    type GetDiscountGroups,
    type SetCurrentRider,
    type SetAccountSharingInfo,
    type SetDiscountGroups,
    type SetRiderDiscount,
    type SetRiderVoiPass,
    type SetRiderPaymentDetails,
    type SetDriversLicenseStatus,
    type SetRiderRideHistory,
    type SetRideParkingPhoto,
    type SubmitEndRide,
    type SetGuestRides,
    type SetActiveRideRider,
    type SetCurrentRideHistoryItem,
    type ResetRiderRideHistory,
    type SetRiderRideHistoryDateFilter,
    type SetRiderVoucherDetails,
} from 'src/redux/rider/rider.types'
import { type AccountSharingInfo, type DriversLicenseStatus } from 'src/api/fm/users/users.model'
import { type RiderPaymentDetails, type RiderVoiPass } from 'src/api/fm/payments/payments.model'
import { type Ride, type RideHistoryItem } from 'src/api/fm/rentals/rentals.model'
import { type RiderVoucherDetails } from 'src/api/fm/discounts/discounts.model'
import { type NavigateFunction } from 'react-router'
import { type CurrentUser } from 'src/redux/auth/auth.types'
import { type BlockTypes } from 'src/pages/riderDetails/blockUserModal/BlockUserModal'
import { type FineItem } from 'src/api/fm/fineHistory/fines.model'

export const setDeleteUserFailed = (data: boolean): DeleteUserFailed => ({
    type: SET_DELETE_USER_FAILED,
    payload: data,
})

export const toggleEndRideModal = (data: Ride | null): ToggleEndRideModal => ({
    type: TOGGLE_END_RIDE_MODAL,
    payload: data,
})

export const getCurrentRiderFullProfile = (id: string): GetCurrentRiderFullProfile => ({
    type: GET_CURRENT_RIDER_FULL_PROFILE,
    payload: id,
})

export const getAccountSharingInfo = (id: string): GetAccountSharingInfo => ({
    type: GET_ACCOUNT_SHARING_INFO,
    payload: id,
})

export const getRideParkingPhoto = (rideId: string): GetRideParkingPhoto => ({
    type: GET_RIDE_PARKING_PHOTO,
    payload: rideId,
})

export const getDiscountGroups = (): GetDiscountGroups => ({
    type: GET_DISCOUNT_GROUPS,
})

export const getRiderDiscount = (id: string): GetRiderDiscount => ({
    type: GET_RIDER_DISCOUNT,
    payload: id,
})

export const getRiderVoiPass = (riderId: string): GetRiderVoiPass => ({
    type: GET_RIDER_VOI_PASS,
    payload: riderId,
})

export const getRiderPaymentDetails = (riderId: string): GetRiderPaymentDetails => ({
    type: GET_RIDER_PAYMENT_DETAILS,
    payload: riderId,
})

export const getDriversLicenseStatus = (riderId: string): GetDriversLicenseStatus => ({
    type: GET_DRIVERS_LICENSE_STATUS,
    payload: riderId,
})

export const setCurrentRider = (rider: Record<string, any> | null): SetCurrentRider => ({
    type: SET_CURRENT_RIDER,
    payload: rider,
})

export const setAccountSharingInfo = (data: AccountSharingInfo): SetAccountSharingInfo => ({
    type: SET_ACCOUNT_SHARING_INFO,
    payload: data,
})

export const setDiscountGroups = (data: RiderDiscountGroup[] | null): SetDiscountGroups => ({
    type: SET_DISCOUNT_GROUPS,
    payload: data,
})

export const setRiderDiscount = (data: RiderDiscount | null): SetRiderDiscount => ({
    type: SET_RIDER_DISCOUNT,
    payload: data,
})

export const setRiderVoiPass = (voiPass: RiderVoiPass): SetRiderVoiPass => ({
    type: SET_RIDER_VOI_PASS,
    payload: voiPass,
})

export const setRiderPaymentDetails = (paymentDetails: RiderPaymentDetails): SetRiderPaymentDetails => ({
    type: SET_RIDER_PAYMENT_DETAILS,
    payload: paymentDetails,
})

export const setDriversLicenseStatus = (status: DriversLicenseStatus): SetDriversLicenseStatus => ({
    type: SET_DRIVERS_LICENSE_STATUS,
    payload: status,
})

export const giveRiderCredits = (riderId: string, amount: number, note: Record<string, any>): GiveRiderCredits => ({
    type: GIVE_RIDER_CREDITS,
    payload: { riderId, amount, note },
})

export const giveRiderDiscounts = (
    riderId: string,
    name: string,
    // description: string,
    internalName: string,
    expiresAt: number,
    amount: number,
    credits: number,
    unlockFeeDiscountPercent: number,
    rideFeeDiscountPercent: number,
    rideFeeDiscountMinutes: number,
    note: Record<string, any>,
): GiveRiderDiscounts => ({
    type: GIVE_RIDER_DISCOUNTS,
    payload: {
        riderId,
        name,
        // description,
        internalName,
        expiresAt,
        amount,
        credits,
        unlockFeeDiscountPercent,
        rideFeeDiscountPercent,
        rideFeeDiscountMinutes,
        note,
    },
})

export const forgiveRiderDebt = (riderId: string): ForgiveRiderDebt => ({
    type: FORGIVE_RIDER_DEBT,
    payload: { riderId },
})

export const editRiderDiscount = (
    riderId: string,
    discountGroupId: string | null,
    validTo: number | null,
): EditRiderDiscount => ({
    type: EDIT_RIDER_DISCOUNT,
    payload: { riderId, discountGroupId, validTo },
})

export const setRiderRideHistory = (data: RideHistoryItem[] | null): SetRiderRideHistory => ({
    type: SET_RIDER_RIDE_HISTORY,
    payload: data,
})

export const setRideParkingPhoto = (data: ParkingPhoto | null): SetRideParkingPhoto => ({
    type: SET_RIDE_PARKING_PHOTO,
    payload: data,
})

export const deleteDriversLicense = (riderId: string): DeleteDriversLicense => ({
    type: DELETE_DRIVERS_LICENSE,
    payload: riderId,
})

export const approveDriversLicenseDocument = (riderId: string): ApproveDriversLicenseDocument => ({
    type: APPROVE_DRIVERS_LICENSE_DOCUMENT,
    payload: riderId,
})

export const approveDriversLicenseFaceCheck = (riderId: string): ApproveDriversLicenseFaceCheck => ({
    type: APPROVE_DRIVERS_LICENSE_FACE_CHECK,
    payload: riderId,
})

export const deleteRider = (payload: { id: string; reason: string; navigateFn: NavigateFunction }): DeleteRider => ({
    type: DELETE_RIDER,
    payload,
})

export const requestIDCheck = (payload: { reason: string; userIds: string[] }): RequestIDCheck => ({
    type: REQUEST_ID_CHECK,
    payload,
})

export const submitEndRide = (payload: {
    ride: Ride | null
    user: CurrentUser | null
    data: { note: { title: string; desc: string }; minutes: number; type: string }
}): SubmitEndRide => ({
    type: SUBMIT_END_RIDE,
    payload,
})

export const setGuestRides = (guestRides: Ride[] | null): SetGuestRides => ({
    type: SET_GUEST_RIDES,
    payload: guestRides,
})

export const setActiveRideRider = (payload: Ride | null): SetActiveRideRider => ({
    type: SET_ACTIVE_RIDE_RIDER,
    payload,
})

export const getRiderRideHistory = (payload: number): GetRiderRideHistory => ({
    type: GET_RIDER_RIDE_HISTORY,
    payload,
})

export const getRideHistoryItem = (rideId: string): GetRideHistoryItem => ({
    type: GET_RIDE_HISTORY_ITEM,
    payload: rideId,
})

export const setCurrentRideHistoryItem = (ride: RideHistoryItem): SetCurrentRideHistoryItem => ({
    type: SET_CURRENT_RIDE_HISTORY_ITEM,
    payload: ride,
})

export const resetRiderRideHistory = (): ResetRiderRideHistory => ({
    type: RESET_RIDER_RIDE_HISTORY,
})

export const setRiderRideHistoryDateFilter = (payload: string | null): SetRiderRideHistoryDateFilter => ({
    type: SET_RIDER_RIDE_HISTORY_DATE_FILTER,
    payload,
})

export const setRiderRideHistorySourceFilter = (payload: string | null) => ({
    type: SET_RIDER_RIDE_HISTORY_SOURCE_FILTER,
    payload,
})

export const blockRider = (payload: { id: string; blockDesc: string; blockTypes: BlockTypes }): BlockRider => ({
    type: BLOCK_RIDER,
    payload,
})

export const expireRiderVoiPass = (riderId: string, ridePlanId: string | undefined): ExpireRiderVoiPass => ({
    type: EXPIRE_RIDER_VOI_PASS,
    payload: { riderId, ridePlanId },
})

export const abandonContract = (riderId: string, contractId: string | undefined): AbandonContract => ({
    type: ABANDON_CONTRACT,
    payload: { riderId, contractId },
})

export const cancelAndRefundContract = (riderId: string, contractId: string | undefined): CancelAndRefundContract => ({
    type: CANCEL_AND_REFUND_CONTRACT,
    payload: { riderId, contractId },
})

export const stopContractRenewal = (riderId: string, contractId: string | undefined): StopContractRenewal => ({
    type: STOP_CONTRACT_RENEWAL,
    payload: { riderId, contractId },
})

export const getRiderVoucherDetails = (riderId: string): GetRiderVoucherDetails => ({
    type: GET_RIDER_VOUCHER_DETAILS,
    payload: riderId,
})

export const setRiderVoucherDetails = (payload: RiderVoucherDetails): SetRiderVoucherDetails => ({
    type: SET_RIDER_VOUCHER_DETAILS,
    payload,
})

export const deleteRiderVouchers = ({ riderId, voucherIds }: { riderId: string; voucherIds: string[] }) => ({
    type: DELETE_RIDER_VOUCHERS,
    payload: {
        riderId,
        voucherIds,
    },
})

export const unblockRiderDeviceSwitch = ({ id, reason }: { id: string; reason: string }) => ({
    type: UNBLOCK_RIDER_DEVICE_SWITCH,
    payload: {
        id,
        reason,
    },
})

export const updateDateOfBirth = ({
    riderId,
    dateOfBirth,
}: {
    riderId: string
    dateOfBirth: string
}): UpdateDateOfBirth => ({
    type: UPDATE_DATE_OF_BIRTH,
    payload: {
        riderId,
        dateOfBirth,
    },
})

export const deleteRiderPhone = (id: string): DeleteRiderPhone => ({
    type: DELETE_RIDER_PHONE,
    payload: id,
})

export const getRiderFineHistory = (id: string): GetRiderFineHistory => ({
    type: GET_RIDER_FINE_HISTORY,
    payload: id,
})

export const setRiderFineHistory = (payload: FineItem[]): SetRiderFineHistory => ({
    type: SET_RIDER_FINE_HISTORY,
    payload,
})

export const resetRiderFineHistory = (): ResetRiderFineHistory => ({
    type: RESET_RIDER_FINE_HISTORY,
})
