import { coral } from 'src/ui-kit/colors/colors'
import { ColumnContainer } from 'src/ui-kit/containers/ColumnContainer'
import { formatUnixDate } from 'src/utils/time/timeUtils'
import styled from '@emotion/styled'
import AccessControl from 'src/components/app/AccessControl'
import { permissions } from 'src/components/app/accessControl.permissions'
import { useAppDispatch } from 'src/redux/app/app.hooks'
import { showParkingPhotoForTask } from 'src/redux/hunterParkingPhotos'

interface Props {
    taskId: string
    dropTime: string | undefined
}

export const HunterParkingPhotoInfo = (props: Props) => {
    const { taskId, dropTime } = props
    const dispatch = useAppDispatch()
    const dropTimeFormatted = dropTime ? formatUnixDate(Number(dropTime)) : undefined

    return (
        <ColumnContainer>
            <span>{dropTimeFormatted}</span>
            <AccessControl allow={permissions.hunter_editor}>
                <ShowPhotoButton
                    onClick={() => dispatch(showParkingPhotoForTask(taskId))}
                    data-testid='show-hunter-parking-photo-btn'
                >
                    Show photo
                </ShowPhotoButton>
            </AccessControl>
        </ColumnContainer>
    )
}

const ShowPhotoButton = styled.span`
    color: ${coral};
    cursor: pointer;
`
