import { type PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import * as colors from 'src/ui-kit/colors/colors'

export function EmptyTablePlaceholder({ children }: PropsWithChildren<unknown>) {
    return (
        <EmptyTextWrapper>
            <EmptyTextStyle data-testid='empty-table-placeholder'>{children}</EmptyTextStyle>
        </EmptyTextWrapper>
    )
}

export const TableFooter = styled.div`
    width: 100%;
    padding: 20px 0;
    text-align: center;
    border-top: 1px solid ${colors.mediumGrey};
    cursor: pointer;
`

const EmptyTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
`

const EmptyTextStyle = styled.p`
    text-align: center;
    color: ${colors.disabledHeaderGray};
    font-size: 12px;
    padding: 44px 0;
`
