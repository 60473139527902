import styled from '@emotion/styled'
import * as colors from 'src/ui-kit/colors/colors'

type Props = {
    onChange: () => void
    checked: boolean
    label?: string
    marginRight?: string
    color?: string
    labelPosition?: string
    marginTop?: string
    dataTestId?: string
    disabled?: boolean
}

const ToggleSwitchButton = (props: Props) => (
    <ToggleSwitchWrapper
        $disabled={!!props.disabled}
        $labelPosition={props.labelPosition}
        $marginRight={props.marginRight}
        $marginTop={props.marginTop}
    >
        {props.disabled ? (
            <SwitchWrapper data-testid={props.dataTestId}>
                <SwitchBg $disabled={props.disabled} $color={props.color} $checked={props.checked} />
                <SwitchHandle
                    $disabled={props.disabled}
                    $checked={props.checked}
                    data-testid='toggle-switch-button-handle'
                />
            </SwitchWrapper>
        ) : (
            <SwitchWrapper data-testid={props.dataTestId} onClick={() => props.onChange()}>
                <SwitchBg $color={props.color} $checked={props.checked} />
                <SwitchHandle $checked={props.checked} data-testid='toggle-switch-button-handle' />
            </SwitchWrapper>
        )}
        {props.label ? <ToggleSwitchLabel $labelPosition={props.labelPosition}>{props.label}</ToggleSwitchLabel> : null}
    </ToggleSwitchWrapper>
)

export default ToggleSwitchButton

const ToggleSwitchWrapper = styled.div<{
    $marginTop?: string
    $marginRight?: string
    $disabled: boolean
    $labelPosition?: string
}>`
    margin-top: ${props => (props.$marginTop ? props.$marginTop : '0px')};
    margin-right: ${props => (props.$marginRight ? props.$marginRight : '0px')};
    opacity: ${props => (props.$disabled ? 0.5 : 1)};
    ${props => (props.$labelPosition && props.$labelPosition === 'inline' ? 'display: flex; align-items: center;' : '')}
`

const ToggleSwitchLabel = styled.div<{ $labelPosition?: string }>`
    font-size: 10px;
    margin-left: ${props => (props.$labelPosition && props.$labelPosition === 'inline' ? '4px' : '0px')};
`

const SwitchWrapper = styled.div`
    position: relative;
    display: inline-block;
    text-align: left;
    opacity: 1;
    border-radius: 10px;
`

const SwitchBg = styled.div<{ $checked: boolean; $color: string | undefined; $disabled?: true }>`
    height: 20px;
    width: 40px;
    background: ${props => (props.$checked ? (props.$color ? props.$color : colors.coral) : colors.grey50)};
    border-radius: 10px;
    cursor: ${props => (props.$disabled ? 'not-allowed' : 'pointer')};
    transition: background 0.25s ease 0s;
`

const SwitchHandle = styled.div<{ $disabled?: true; $checked: boolean }>`
    height: 18px;
    width: 18px;
    background: ${colors.white};
    cursor: ${props => (props.$disabled ? 'not-allowed' : 'pointer')};
    display: inline-block;
    border-radius: 50%;
    position: absolute;
    transform: ${props => (props.$checked ? 'translateX(21px)' : 'translateX(1px)')};
    top: 1px;
    transition:
        background-color 0.25s ease 0s,
        transform 0.25s ease 0s,
        box-shadow 0.15s ease 0s;
`
