import { type ComponentPropsWithRef } from 'react'
import styled from '@emotion/styled'

export const VoiIcon = (props: ComponentPropsWithRef<'img'>) => {
    const { src, ...rest } = props

    return <ImgStyled src={src} {...rest} />
}

const ImgStyled = styled.img`
    width: 18px;
    height: 18px;
`
