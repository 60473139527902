import { pickBy } from 'lodash-es'
import { type ChangeEvent, type FormEvent, useEffect, useState } from 'react'
import { ClickAwayListener } from '@mui/material'
import { OpenInNew as OpenInNewIcon, InfoOutlined as InfoOutlinedIcon } from '@mui/icons-material'
import {
    type TaskType,
    EXECUTION_PRIORITY_OPTIONS_WITHOUT_LOW,
    EXECUTION_PRIORITY_LABELS,
} from 'src/api/fm/tasks/tasks.model'
import { permissions } from 'src/components/app/accessControl.permissions'
import { checkIfRolesHavePermission } from 'src/components/app/utils/accessControl/accessControlUtils'
import {
    INITIAL_TASK,
    noAssigneeTasks,
    requireDescriptionTasks,
    type TaskFormProps,
    type CreateTaskVehicleStatus,
    TASK_DESCRIPTION_MAX_LENGTH,
    TASK_TYPE_OPTIONS_FOR_CREATE_NEW_TASK,
} from 'src/components/parts/active-tasks/createTaskForm/task.types'
import { useAppSelector } from 'src/redux/app/app.hooks'
import { selectCurrentUserRoles } from 'src/redux/auth'
import { Button } from 'src/ui-kit/button/Button'
import { Input, FormRow, FormColumn } from 'src/ui-kit/forms/Forms'
import { Popup } from 'src/ui-kit/popup/Popup'
import { VoiSelect } from 'src/ui-kit/voiSelect/VoiSelect'
import styled from '@emotion/styled'
import { firstLetterUpperCase } from 'src/utils/string/stringUtils'
import { TooltipDark } from 'src/ui-kit/textTooltip/VoiTooltip'
import { BUTTON_COLORS } from 'src/ui-kit/button/constants'
import * as colors from 'src/ui-kit/colors/colors'
import { selectPermissionsForRoles } from 'src/redux/permissionsForRoles'

interface Props {
    title: string
    close: () => void
    onSubmit: (task: TaskFormProps) => void
    task?: TaskFormProps
    orgId?: string
    vehicleShortInput?: boolean
    dataTestId?: string
}

const allowRiderIncentivizedOptions = ['yes', 'no']
const vehicleStatusOptions = ['bounty', 'ready']

export const CreateTaskForm = (props: Props) => {
    const { close, task, onSubmit, vehicleShortInput, dataTestId } = props
    const roles = useAppSelector(selectCurrentUserRoles)
    const permissionsForRoles = useAppSelector(selectPermissionsForRoles)
    const [newTask, setNewTask] = useState<TaskFormProps>({ ...INITIAL_TASK, ...task })
    const [openToolTip, setOpenTooltip] = useState(false)
    const [taskWillBeUnassigned, setTaskWillBeUnassigned] = useState(!newTask.email) // Checks if the new task will be unassigned upon creation or not.

    useEffect(() => {
        setTaskWillBeUnassigned(!newTask.email?.trim())
    }, [newTask.email])

    const handleTooltipOpen = () => {
        setOpenTooltip(true)
    }

    const handleTooltipClose = () => {
        setOpenTooltip(false)
    }

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()

        let clean = { ...newTask }

        // If task will be assigned (i.e. "In progress") there's no need to pass "vehicleStatus"
        if (!taskWillBeUnassigned) {
            clean.vehicleStatus = undefined
        }

        // Clean up empty/undefined fields
        clean = pickBy(clean, value => value)

        onSubmit(clean)
        close()
    }

    const selectType = (type: TaskType) => {
        const defaultTaskValues: TaskFormProps = {
            type,
            info: '',
            vehicleStatus: undefined,
        }

        if (type === 'transport') {
            defaultTaskValues.info = 'Transport to VOI Warehouse'
            defaultTaskValues.vehicleStatus = 'bounty'
        } else if (type === 'in_field_quality_check') {
            defaultTaskValues.vehicleStatus = 'bounty'
        }

        setNewTask({ ...newTask, ...defaultTaskValues })
    }

    const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
        const rawInput = e.target.value
        const slicedInput =
            rawInput.length > TASK_DESCRIPTION_MAX_LENGTH ? rawInput.slice(0, TASK_DESCRIPTION_MAX_LENGTH) : rawInput

        setNewTask({ ...newTask, info: slicedInput })
    }

    const shouldDisablePriorityInput = !checkIfRolesHavePermission({
        roles,
        allow: permissions.tasks_priority_editor,
        permissionsForRoles,
    })

    return (
        <Popup title={props.title} close={() => props.close()} dataTestId={dataTestId}>
            <TaskFormContainer onSubmit={e => handleSubmit(e)}>
                <FormRow style={{ justifyContent: 'flex-start' }}>
                    <FormColumn>
                        <VoiSelectStyled
                            required
                            title='Task type'
                            value={newTask.type ?? null}
                            options={Object.keys(TASK_TYPE_OPTIONS_FOR_CREATE_NEW_TASK)}
                            onChange={(_, value) => selectType(value as TaskType)}
                            getOptionLabel={key =>
                                TASK_TYPE_OPTIONS_FOR_CREATE_NEW_TASK[
                                    key as keyof typeof TASK_TYPE_OPTIONS_FOR_CREATE_NEW_TASK
                                ]
                            }
                            dataTestId='create-task-form-type-select'
                        />
                    </FormColumn>
                    {newTask.type === 'rebalance' && (
                        <FormColumn>
                            <div style={{ display: 'flex' }}>
                                <div style={{ margin: '0 0 10px 10px', flex: '1' }}>Incentivized *</div>
                                <ClickAwayListener onClickAway={handleTooltipClose}>
                                    <div>
                                        <TooltipDark title={<ClickableTitle />} open={openToolTip}>
                                            <IncentivizedWrapper onClick={handleTooltipOpen}>
                                                MORE INFO
                                                <OpenInNewIcon
                                                    style={{
                                                        color: BUTTON_COLORS.CORAL,
                                                        fontSize: '12px',
                                                    }}
                                                />
                                            </IncentivizedWrapper>
                                        </TooltipDark>
                                    </div>
                                </ClickAwayListener>
                            </div>
                            <VoiSelectStyled
                                required
                                value={newTask?.riderIncentivized ? 'yes' : 'no'}
                                options={allowRiderIncentivizedOptions}
                                onChange={(_, value) => setNewTask({ ...newTask, riderIncentivized: value === 'yes' })}
                                getOptionLabel={firstLetterUpperCase}
                                dataTestId='create-task-rider-rebalance-select'
                            />
                        </FormColumn>
                    )}
                    {(newTask.type === 'in_field_quality_check' || newTask.type === 'transport') && (
                        <FormColumn>
                            <div style={{ display: 'flex' }}>
                                <div style={{ margin: '0 0 10px 10px' }}>Vehicle state *</div>
                                {taskWillBeUnassigned && (
                                    <TooltipDark
                                        title="Even though 'Ready' is selected the task may get created as 'Bounty' depending on the vehicle battery level"
                                        arrow
                                        disableFocusListener
                                        enterTouchDelay={0}
                                        leaveTouchDelay={20000}
                                    >
                                        <InfoOutlinedIcon
                                            style={{
                                                fontSize: '16px',
                                                marginLeft: '5px',
                                                cursor: 'pointer',
                                                color: colors.supportingNeutralGrey04,
                                            }}
                                        />
                                    </TooltipDark>
                                )}
                            </div>
                            {taskWillBeUnassigned ? ( // Displaying the selectable Vehicle Status dropdown is only relevant for new tasks that will be unassigned, since the Ready option would have no effect when creating an assigned task (i.e. "In progress")
                                <VoiSelectStyled
                                    required
                                    value={newTask.vehicleStatus}
                                    options={vehicleStatusOptions}
                                    onChange={(_, value) =>
                                        setNewTask({
                                            ...newTask,
                                            vehicleStatus: value as CreateTaskVehicleStatus,
                                        })
                                    }
                                    getOptionLabel={firstLetterUpperCase}
                                    dataTestId='create-task-form-vehicle-status-select'
                                />
                            ) : (
                                <VoiSelectStyled
                                    required
                                    value='In Progress'
                                    options={['In Progress']}
                                    onChange={() => undefined}
                                    disabled
                                    dataTestId='create-task-form-vehicle-status-select'
                                />
                            )}
                        </FormColumn>
                    )}
                </FormRow>
                <VoiSelectStyled
                    required
                    disabled={shouldDisablePriorityInput}
                    title='Priority'
                    value={newTask.executionPriority}
                    options={EXECUTION_PRIORITY_OPTIONS_WITHOUT_LOW}
                    onChange={(_, value) => setNewTask({ ...newTask, executionPriority: value })}
                    getOptionLabel={option => EXECUTION_PRIORITY_LABELS[option]}
                    dataTestId='create-task-form-execution-priority-select'
                />
                {vehicleShortInput && (
                    <Input
                        dataTestId='create-task-form-vehicle-short-input'
                        onChange={(e: any) => setNewTask({ ...newTask, vehicleShort: e.target.value })}
                        required
                        value={newTask.vehicleShort}
                        disabled={task && !!task.vehicleShort}
                        label='Vehicle short'
                        placeholder='Add short...'
                    />
                )}
                <Input
                    dataTestId='create-task-form-info-input'
                    onChange={handleDescriptionChange}
                    required={requireDescriptionTasks.includes(newTask.type!)}
                    maxLength={TASK_DESCRIPTION_MAX_LENGTH}
                    value={newTask.info}
                    label={`Description — Max. ${TASK_DESCRIPTION_MAX_LENGTH} characters. (To be displayed on the vehicle details in the Voi Fleet App.)`}
                    placeholder='Add description...'
                />
                <Input
                    dataTestId='create-task-form-info-internal-input'
                    label='Internal note'
                    required={false}
                    value={newTask.infoInternal}
                    placeholder='Add note...'
                    onChange={(e: any) => setNewTask({ ...newTask, infoInternal: e.target.value })}
                />
                {!noAssigneeTasks.includes(newTask.type!) && (
                    <Input
                        dataTestId='create-task-form-hunter-or-org-email-input'
                        disabled={task && !!task.email}
                        label='Hunter or organization email'
                        value={newTask.email}
                        type='email'
                        placeholder='email@example.com'
                        onChange={(e: any) => setNewTask({ ...newTask, email: e.target.value })}
                    />
                )}
                <Button dataTestId='create-task-form-submit-button' isStretched actionType='submit'>
                    SUBMIT
                </Button>
            </TaskFormContainer>
        </Popup>
    )
}

const ClickableTitle = () => {
    return (
        <>
            By enabling Rider Incentivized, users of the Rider App will be incentivized with discounts on their Rides to
            take the vehicle from its current location. See{' '}
            <a
                style={{ pointerEvents: 'auto' }}
                className='CustomTooltip-child'
                href={'https://voidev.atlassian.net/wiki/spaces/FI/pages/79614672899/Rider+Rebalancing'}
                target='_blank'
                rel='noreferrer'
            >
                here
            </a>{' '}
            for more info.
        </>
    )
}

export const TaskFormContainer = styled.form`
    display: flex;
    flex-direction: column;
`

const VoiSelectStyled = styled(VoiSelect)`
    margin-bottom: 15px;
` as typeof VoiSelect

const IncentivizedWrapper = styled.div`
    color: ${BUTTON_COLORS.CORAL};
    font-family: 'Overpass Mono', monospace;
    font-weight: 700;
    font-size: 10px;
    cursor: pointer;
    display: flex;
    margin-right: 7px;
`
