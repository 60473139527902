import { type Task } from 'src/api/fm/tasks/tasks.model'
import { firstLetterUpperCase } from 'src/utils/string/stringUtils'
import { UserEmailLink } from 'src/components/parts/history/hunter/user-email-link/UserEmailLink'
import { ColumnContainer } from 'src/ui-kit/containers/ColumnContainer'
import { findEvent } from 'src/utils/tasks/tasksUtils'
import { formatUnixDate } from 'src/utils/time/timeUtils'
import styled from '@emotion/styled'

interface Props {
    task: Task
}

export const TaskStatusInfo = (props: Props) => {
    const { status, auditLog, deletionReason } = props.task

    if (status === 'deleted') {
        const deletionAuditLog = findEvent('deleted', auditLog) ?? {
            operatorEmail: null,
            operatorId: null,
            createdAt: null,
        }
        const { operatorEmail, operatorId, createdAt } = deletionAuditLog
        const formattedCreatedAt = createdAt && formatUnixDate(Number(createdAt))

        return (
            <ColumnContainer>
                <DeletionReasonStyled>Deleted by {firstLetterUpperCase(deletionReason)}</DeletionReasonStyled>
                {operatorEmail && (
                    <UserEmailLink email={operatorEmail} href={`/riders/${operatorId}`} operatorType='user' />
                )}
                {formattedCreatedAt && <span>{formattedCreatedAt}</span>}
            </ColumnContainer>
        )
    }

    return (
        <ColumnContainer>
            <span>{firstLetterUpperCase(status)}</span>
        </ColumnContainer>
    )
}

const DeletionReasonStyled = styled.span`
    white-space: nowrap;
`
