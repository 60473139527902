import { TableCell } from '@mui/material'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import { type Task } from 'src/api/fm/tasks/tasks.model'
import { removeActiveTaskFromBulk, addActiveTaskToBulk, selectTaskBulkDropList } from 'src/redux/task'

interface Props {
    task: Task
}

function isTaskInBulkList(task: Task, bulkList: Task[]) {
    return bulkList.some(item => item.id === task.id)
}

export const AddToBulk = ({ task }: Props) => {
    const bulkDropList = useAppSelector(selectTaskBulkDropList)

    const dispatch = useAppDispatch()

    const handleChange = (checked: boolean) => {
        if (checked) {
            dispatch(addActiveTaskToBulk(task))
        } else {
            dispatch(removeActiveTaskFromBulk(task))
        }
    }

    return (
        <TableCell>
            {bulkDropList && (
                <input
                    checked={isTaskInBulkList(task, bulkDropList)}
                    type='checkbox'
                    onChange={e => handleChange(e.target.checked)}
                />
            )}
        </TableCell>
    )
}
