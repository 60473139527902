import styled from '@emotion/styled'
import * as colors from 'src/ui-kit/colors/colors'
import { fontPrimary, fontSecondary } from 'src/ui-kit/fonts/fontFamily'

export const ButtonText = styled.div<{
    $fontSize?: string
    $verticalAlign?: true
    $verticalAligns?: true
    $color?: string
}>`
    font-size: ${props => (props.$fontSize ? props.$fontSize : '14px')};
    color: ${props => (props.$color ? props.$color : colors.black)};
    ${fontSecondary}
    line-height: ${props => (props.$fontSize ? props.$fontSize : '14px')};
    font-weight: 700;
    margin: 0;
    ${props => props.$verticalAlign && 'display: flex;'}
    ${props => props.$verticalAlign && 'align-items: center;'}
`

export const LabelText = styled.div<{ $color?: string }>`
    font-size: 12px;
    color: ${props => (props.$color ? props.$color : colors.black)};
    ${fontPrimary}
    line-height: 1.24;
    font-weight: 700;
    margin: 0;
`

export const SmallLabel = styled.p`
    font-size: 12px;
    color: ${colors.grey};
    margin-bottom: 0;
`

export const P = styled.p`
    font-size: 12px;
    margin: 0;
    color: ${colors.black};
    ${fontPrimary}
`
