import Logout from 'src/assets/images/nav/logout.svg'
import PersonIcon from 'src/assets/images/nav/person.svg'
import PersonDarkIcon from 'src/assets/images/nav/person-dark.svg'
import { getCurrentRoles } from 'src/components/app/utils/accessControl/accessControlUtils'
import { getStringifiedOwnerRoles } from 'src/pages/permissions/utils'
import { useAppDispatch, useAppSelector } from 'src/redux/app/app.hooks'
import { logoutUser, selectCurrentUser, selectCurrentUserRoles } from 'src/redux/auth'
import { Row } from 'src/ui-kit/layout/Layout'
import { TooltipMenu } from 'src/ui-kit/tooltipMenu/TooltipMenu'
import { VoiIcon } from 'src/ui-kit/voiIcon/VoiIcon'
import styled from '@emotion/styled'

interface Props {
    useDarkIcon?: boolean
}

export const RolesAndLogout = (props: Props) => {
    const { useDarkIcon = false } = props
    const dispatch = useAppDispatch()

    const currentUser = useAppSelector(selectCurrentUser)
    const roles = useAppSelector(selectCurrentUserRoles)

    const voiOrgRoles = getCurrentRoles(roles)
    const roleList = getStringifiedOwnerRoles(voiOrgRoles)
    const photoUrl = currentUser?.photoUrl // Either the user's Google account photo or Google's placeholder image

    return (
        <TooltipMenu
            placement='left'
            anchor={
                photoUrl ? (
                    <PhotoIcon src={photoUrl} data-testid='logout-tooltip' />
                ) : (
                    <VoiIcon src={useDarkIcon ? PersonDarkIcon : PersonIcon} data-testid='logout-tooltip' />
                )
            }
        >
            <RoleList data-testid='logout-user-role'>{roleList}</RoleList>
            <Row data-testid='logout-button' onClick={() => dispatch(logoutUser())}>
                <VoiIcon src={Logout} style={{ marginRight: '10px' }} />
                <LogoutText>LOGOUT</LogoutText>
            </Row>
        </TooltipMenu>
    )
}

const RoleList = styled.span`
    display: flex;
    font-family: Montserrat, monospace;
    line-height: 1.24;
    font-weight: 700;
    font-size: 12px;
    white-space: initial;
`

const LogoutText = styled.span`
    font-family: 'Overpass Mono', monospace;
    line-height: 14px;
    font-weight: 700;
`

const PhotoIcon = styled.img`
    width: 30px;
    border-radius: 15px;
`
