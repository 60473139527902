import { type PropsWithChildren } from 'react'
import styled from '@emotion/styled'
import * as colors from 'src/ui-kit/colors/colors'
import { fontSecondary } from 'src/ui-kit/fonts/fontFamily'

export const CardTabsWrapper = ({ children }: PropsWithChildren<unknown>) => {
    return <CardTabsWrapperStyles>{children}</CardTabsWrapperStyles>
}

interface CardTabProps {
    dataTestId?: string
    onClick?: () => void
    active: boolean
    title: string
    amount?: number
}

export const CardTab = (props: CardTabProps) => (
    <Tab
        data-testid={props.dataTestId}
        className={props.active ? 'active' : ''}
        $active={props.active}
        onClick={props.onClick}
    >
        <Divider data-testid='card-tab-divider' className='card-tab-divider' />
        {props.title.toUpperCase()} {props.amount ? <TabAmount>{props.amount}</TabAmount> : null}
    </Tab>
)

const CardTabsWrapperStyles = styled.div`
    display: flex;
    width: 100%;
    font-size: 16px;
    ${fontSecondary}
    font-weight: 600;
    border-radius: 8px 8px 0 0;
    margin-left: 0;
    margin-right: 0;
    overflow: auto hidden;
`

const Divider = styled.span`
    display: none;
    position: absolute;
    left: 0;
    top: 10px;
    width: 1px;
    height: 40px;
    background: ${colors.coral};
`

const Tab = styled.div<{ $active: boolean }>`
    background-color: ${props => (props.$active ? colors.white : colors.pink)};
    display: flex;
    align-items: center;
    justify-content: space-between;

    &:hover {
        cursor: pointer;
    }

    flex-basis: 100%;
    height: 30px;
    padding: 15px;
    color: ${colors.coral};
    position: relative;

    &:not(.active) + &:not(.active) .card-tab-divider {
        display: inline;
    }
`

const TabAmount = styled.div`
    border-radius: 50%;
    background-color: ${colors.coral};
    color: ${colors.white};
    font-size: 12px;
    height: 34px;
    width: 34px;
    display: flex;
    align-items: center;
    justify-content: center;
`
