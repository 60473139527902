import { TextField, Autocomplete, Paper } from '@mui/material'
import { styled as styledMui } from '@mui/material/styles'
import { type ChangeEvent, type CSSProperties, type Ref, useState } from 'react'
import styled from '@emotion/styled'
import DropDown from 'src/assets/images/ic_dropdown.svg'
import * as colors from 'src/ui-kit/colors/colors'
import { SELECT_WIDTH } from 'src/ui-kit/forms/Forms'

interface SelectProps<T extends string> {
    inputRef?: Ref<HTMLInputElement>
    isClearable?: boolean
    title?: string
    value: T | undefined | null // TODO: Remove 'undefined' in order to avoid local 'uncontrolled component' console warning
    options: T[]
    required?: boolean
    disabled?: boolean
    className?: string
    dataTestId?: string
    name?: string
    placeholder?: string
    onChange: (event: ChangeEvent<unknown>, value: T) => void
    getOptionLabel?: (option: T) => string
    filterOptions?: (options: T[]) => T[]
    style?: CSSProperties
    selectStyle?: CSSProperties
}

export function VoiSelect<T extends string>(props: SelectProps<T>) {
    const [inputValue, setInputValue] = useState('')
    const {
        inputRef,
        isClearable,
        title,
        value,
        options,
        required,
        disabled,
        className,
        dataTestId,
        name,
        placeholder = 'Select...',
        onChange,
        getOptionLabel,
        filterOptions,
        style,
        selectStyle,
    } = props

    return (
        <div className={className} style={style}>
            {title && (
                <Label>
                    {title} {required && '*'}
                </Label>
            )}
            <StyledAutocomplete
                style={selectStyle}
                size='small'
                disableClearable={!isClearable}
                disabled={disabled}
                value={value}
                onChange={(event, value) => onChange(event, value as T)}
                inputValue={inputValue}
                onInputChange={(_, newInputValue) => {
                    setInputValue(newInputValue)
                }}
                options={options}
                getOptionLabel={getOptionLabel as (option: unknown) => string}
                filterOptions={filterOptions as (options: unknown[]) => unknown[]}
                renderInput={params => (
                    <TextField
                        inputRef={inputRef}
                        {...params}
                        variant='outlined'
                        required={required}
                        data-testid={dataTestId}
                        name={name}
                        placeholder={placeholder}
                        slotProps={{
                            input: {
                                ...params.InputProps,
                                endAdornment: disabled ? null : (
                                    <>
                                        <ArrowDownIcon src={DropDown} alt='dropdown icon' />
                                        {params.InputProps.endAdornment}
                                    </>
                                ),
                            },
                        }}
                    />
                )}
                slots={{
                    paper: StyledPaper,
                }}
            />
        </div>
    )
}

const StyledAutocomplete = styledMui(Autocomplete)({
    '&.MuiAutocomplete-root': {
        maxWidth: SELECT_WIDTH,
    },
    '& .MuiAutocomplete-inputRoot': {
        '&.MuiOutlinedInput-root': {
            backgroundColor: colors.white,
            lineHeight: '1.1876em',

            '& .MuiAutocomplete-input': {
                padding: '4.5px 4px 4.5px 6px',
                fontFamily: '"Montserrat", monospace',
                fontSize: 14,
                height: '1.1876em',

                '&.Mui-disabled': {
                    WebkitTextFillColor: colors.grey,
                },
            },

            '& .MuiOutlinedInput-notchedOutline': {
                border: '1px solid #ddd',
            },

            '& .MuiAutocomplete-endAdornment': {
                '& .MuiAutocomplete-popupIndicator': {
                    opacity: 0,
                },
            },

            '&.Mui-disabled': {
                backgroundColor: colors.lightGrey,

                '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                },
            },

            '&.Mui-focused': {
                '& .MuiOutlinedInput-notchedOutline': {
                    border: `1px solid ${colors.coral}`,
                },
                '&>img': {
                    borderLeft: `1px solid ${colors.coral}`,
                },
            },
        },
    },
})

const StyledPaper = styledMui(Paper)({
    '&.MuiPaper-root': {
        margin: '4px 0',
    },
    '& > ul.MuiAutocomplete-listbox': {
        padding: 0,
    },
    '& .MuiAutocomplete-listbox': {
        '& .MuiAutocomplete-option': {
            fontFamily: '"Montserrat", monospace',
            fontSize: 14,
            borderBottom: '1px solid #F2F2F2',
            backgroundColor: '#fff',

            '&.Mui-focused': {
                backgroundColor: '#FAFAFA',

                '&[aria-selected="true"]': {
                    backgroundColor: '#fff',
                },
            },

            '&[aria-selected="true"]': {
                backgroundColor: '#fff',

                '&:hover': {
                    backgroundColor: '#FAFAFA',
                },
            },
        },
    },
})

const Label = styled.label`
    display: flex;
    font-size: 14px;
    color: ${colors.black};
    margin: 0 0 10px 10px;
`

const ArrowDownIcon = styled.img`
    position: absolute;
    right: 0;
    padding: 8px 5px 8px 4px;
    border-left: 1px solid #ddd;
`
