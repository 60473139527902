import { createTheme, alpha, type ThemeOptions } from '@mui/material/styles'
import { grey } from '@mui/material/colors'
import * as colors from 'src/ui-kit/colors/colors'

export const getAppTheme = () => {
    const paletteTheme = createTheme({
        palette: {
            grey: {
                main: grey[300],
                dark: grey[400],
            },
        },
    })

    const themeOptions: ThemeOptions = {
        components: {
            MuiTableContainer: {
                styleOverrides: {
                    root: {
                        '&.scrollable': {
                            maxHeight: '680px',
                            overflowY: 'auto',
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    root: {
                        backgroundColor: colors.white,
                        color: colors.black,
                        fontFamily: "'Montserrat', monospace",
                        fontSize: '13px',
                        borderColor: colors.dividerGrey,
                        verticalAlign: 'middle',

                        '&:first-child': {
                            paddingLeft: '24px',
                        },

                        '&:last-child': {
                            paddingRight: '24px',
                        },
                    },
                    head: {
                        border: 0,
                        backgroundColor: colors.lightGrey,
                        color: colors.black,
                        fontFamily: "'Overpass Mono', monospace",
                        fontWeight: 700,
                        textTransform: 'uppercase',
                        fontSize: '13px',
                        lineHeight: '13px',
                        letterSpacing: 0,
                    },
                },
            },
            MuiTablePagination: {
                styleOverrides: {
                    select: {
                        fontFamily: "'Montserrat', monospace",
                    },
                    selectLabel: {
                        fontFamily: "'Montserrat', monospace",
                    },
                    displayedRows: {
                        fontFamily: "'Montserrat', monospace",
                    },
                },
            },
            MuiTableSortLabel: {
                styleOverrides: {
                    icon: {
                        // Fix alignment of header text and sort icon in Firefox
                        '@supports (-moz-appearance: none)': {
                            marginTop: '-1px',
                        },
                    },
                },
            },
            MuiTooltip: {
                defaultProps: {
                    disableInteractive: true,
                },
            },
            MuiButton: {
                styleOverrides: {
                    root: {
                        variants: [
                            {
                                props: { variant: 'contained', color: 'grey' },
                                style: {
                                    color: paletteTheme.palette.getContrastText(paletteTheme.palette.grey[300]),
                                },
                            },
                            {
                                props: { variant: 'outlined', color: 'grey' },
                                style: {
                                    color: paletteTheme.palette.text.primary,
                                    borderColor: 'rgba(0, 0, 0, 0.23)',
                                    ...paletteTheme.applyStyles('dark', {
                                        borderColor: 'rgba(255, 255, 255, 0.23)',
                                    }),

                                    '&.Mui-disabled': {
                                        border: `1px solid ${paletteTheme.palette.action.disabledBackground}`,
                                    },

                                    '&:hover': {
                                        borderColor: 'rgba(0, 0, 0, 0.23)',
                                        ...paletteTheme.applyStyles('dark', {
                                            borderColor: 'rgba(255, 255, 255, 0.23)',
                                        }),

                                        backgroundColor: alpha(
                                            paletteTheme.palette.text.primary,
                                            paletteTheme.palette.action.hoverOpacity,
                                        ),
                                    },
                                },
                            },
                            {
                                props: { color: 'grey', variant: 'text' },
                                style: {
                                    color: paletteTheme.palette.text.primary,

                                    '&:hover': {
                                        backgroundColor: alpha(
                                            paletteTheme.palette.text.primary,
                                            paletteTheme.palette.action.hoverOpacity,
                                        ),
                                    },
                                },
                            },
                        ],
                    },
                },
            },
        },
    }

    return createTheme(paletteTheme, themeOptions)
}
