import styled from '@emotion/styled'
import SatelliteMap from 'src/assets/images/map_type_satelite.svg'
import RegularMap from 'src/assets/images/map_type_regular.svg'
import { zIndex } from 'src/constants/zIndex'

export enum MapView {
    Streets = 'streets',
    Satellite = 'satellite',
}

interface Props {
    currentMapView: MapView
    setMapView: (mapView: MapView) => void
}

export function ToggleMapViewButton({ currentMapView, setMapView }: Props) {
    const handleToggleMapView = () => {
        if (currentMapView === MapView.Satellite) {
            setMapView(MapView.Streets)
        } else {
            setMapView(MapView.Satellite)
        }
    }

    return (
        <MapViewButton
            src={currentMapView === MapView.Satellite ? SatelliteMap : RegularMap}
            onClick={handleToggleMapView}
            height={56}
            width={56}
        />
    )
}

const MapViewButton = styled.img`
    position: absolute;
    z-index: ${zIndex.medium};
    right: 0;
    bottom: 0;
    cursor: pointer;
`
