import { Link } from 'react-router'
import { useHover } from 'react-use'
import styled from '@emotion/styled'
import TextTooltip from 'src/ui-kit/textTooltip/TextTooltip'
import { shortenEmail } from 'src/components/parts/history/hunter/user-email-link/userEmailLinkUtils'

interface UserEmailLinkProps {
    email: string
    href: string
    operatorType: 'hunter' | 'user'
}

export function UserEmailLink({ email, href, operatorType }: UserEmailLinkProps) {
    const operatorLabel = operatorType === 'hunter' ? 'HUNTER:' : 'USER:'
    const linkElement = <Link to={href}>{shortenEmail(email)}</Link>

    const [hoverable, isHovering] = useHover(linkElement)

    return (
        <UserEmailLinkContainer>
            {hoverable}
            {isHovering && (
                <TextTooltip top='-58px' left='-9px'>
                    {operatorLabel}
                    <br />
                    {email}
                </TextTooltip>
            )}
        </UserEmailLinkContainer>
    )
}

const UserEmailLinkContainer = styled.div`
    position: relative;
`
