import styled from '@emotion/styled'
import * as timeUtils from 'src/utils/time/timeUtils'
import { type Operator } from 'src/utils/tasks/tasksUtils'
import { type TaskEventState } from 'src/api/fm/tasks/tasks.model'
import { UserEmailLink } from 'src/components/parts/history/hunter/user-email-link/UserEmailLink'
import { ColumnContainer } from 'src/ui-kit/containers/ColumnContainer'

interface TaskEventInfoProps {
    taskState: TaskEventState
    hunter?: Operator
    user?: Operator
    dataTestId?: string
}

export const TaskEventInfo = ({ taskState, hunter, user, dataTestId }: TaskEventInfoProps) => {
    const { time, battery, batterySerial } = taskState
    const datetime = time ? timeUtils.formatUnixDate(Number(time)) : undefined

    return (
        <ColumnContainer data-testid={dataTestId}>
            {datetime && <span>{datetime}</span>}
            {hunter && <UserEmailLink email={hunter.email} href={`/hunters/${hunter.id}`} operatorType='hunter' />}
            {user && <UserEmailLink email={user.email} href={`/riders/${user.id}`} operatorType='user' />}
            {battery && <BatteryInfo batteryPct={battery} />}
            {batterySerial && <BatterySerial serialNumber={batterySerial} />}
        </ColumnContainer>
    )
}

const BatteryTitle = styled.label`
    font-weight: 600;
    margin-top: 15px;
    margin-right: 5px;
`

export function BatteryInfo({ batteryPct }: { batteryPct?: number }) {
    if (typeof batteryPct !== 'number') {
        return null
    }

    return (
        <div>
            <BatteryTitle>Battery</BatteryTitle>
            {formatBatteryPct(batteryPct)}
        </div>
    )
}

function formatBatteryPct(battery: number) {
    return battery ? `${battery} %` : '-'
}

function BatterySerial({ serialNumber }: { serialNumber: string }) {
    return (
        <div>
            <BatteryTitle>Battery ID:</BatteryTitle>
            {serialNumber}
        </div>
    )
}
