import { get, orderBy } from 'lodash-es'

export enum SortOrder {
    Asc = 'asc',
    Desc = 'desc',
}

export const sortByString = <T>(collection: T[], path: PathsToFields<T>, order: SortOrder) =>
    sortByBase(collection, path, order)
export const sortByTimeStamp = <T>(collection: T[], path: PathsToFields<T>, order: SortOrder) =>
    sortByBase(collection, path, order, { dataType: 'unix' })
export const sortByNumber = <T>(collection: T[], path: PathsToFields<T>, order: SortOrder) =>
    sortByBase(collection, path, order, { dataType: 'number' })

const sortByBase = <T>(
    collection: T[],
    path: string,
    order: SortOrder,
    options: SortBaseOptions = { dataType: 'string' },
) => {
    const getter =
        options?.dataType === 'string'
            ? (obj: T, path: string) => get(obj, path)
            : (obj: T, path: string) => parseInt(get(obj, path))

    return orderBy(collection, obj => getter(obj, path), order)
}

interface SortBaseOptions {
    dataType: 'unix' | 'string' | 'number'
}

type Dot<T extends string, U extends string> = '' extends U ? T : `${T}.${U}`
type StopFields = string | number | boolean | symbol
type PathsToFields<T> = T extends StopFields
    ? ''
    : {
          [K in Extract<keyof T, string>]: NonNullable<T[K]> extends any[] ? never : K | Dot<K, PathsToFields<T[K]>>
      }[Extract<keyof T, string>]
