import styled from '@emotion/styled'
import { fontPrimary } from 'src/ui-kit/fonts/fontFamily'

export const ErrorContainer = styled.div<{ $padding: string }>`
    background-color: #ed6a5a;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    /* Take available space in normal and flex containers */
    flex: 1 1 auto;
    ${({ $padding = '8px' }) => `
        width: calc(100% - 2 * ${$padding});
        height: calc(100% - 2 * ${$padding});
        padding: ${$padding};
    `};
`

export const ErrorMessage = styled.div<{ $fontSize?: string }>`
    font-size: ${({ $fontSize = '16px' }) => $fontSize};
    ${fontPrimary}
    font-weight: 400;
    color: white;
`
