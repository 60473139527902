import styled from '@emotion/styled'
import { MAP_AREA_TYPES } from 'src/redux/mapSettings/mapSettings.types'
import { type AreaType } from 'src/api/fm/zones/zones.model'
import { firstLetterUpperCase } from 'src/utils/string/stringUtils'
import { TooltipMenu } from 'src/ui-kit/tooltipMenu/TooltipMenu'
import { Checkbox } from 'src/ui-kit/checkbox/Checkbox'
import IconEye from 'src/assets/images/ic_eye.svg'
import IconEyeCrossed from 'src/assets/images/ic_eye_crossed.svg'
import { zIndex } from 'src/constants/zIndex'

interface Props {
    onOpenSelectAreas: () => void
    onCloseSelectAreas: () => void
    onToggleShownAreaType: (areaType: AreaType) => void
    shownAreaTypes: AreaType[]
}

export function ToggleVisibleAreasButton({
    onOpenSelectAreas,
    onCloseSelectAreas,
    onToggleShownAreaType,
    shownAreaTypes,
}: Props) {
    const areAllAreaTypesSelected = MAP_AREA_TYPES.every(type => shownAreaTypes.includes(type))

    return (
        <VisibleAreasButton>
            <TooltipMenu
                placement='left-end'
                anchor={<img src={areAllAreaTypesSelected ? IconEye : IconEyeCrossed} height={48} width={48} alt='' />}
                closeOnClickMenuItem={false}
                onOpen={onOpenSelectAreas}
                onClose={onCloseSelectAreas}
            >
                {MAP_AREA_TYPES.map(areaType => (
                    <StyledCheckbox
                        key={areaType}
                        label={firstLetterUpperCase(areaType)}
                        checked={shownAreaTypes.includes(areaType)}
                        onChange={() => onToggleShownAreaType(areaType)}
                        checkboxSize='small'
                        labelSize='small'
                    />
                ))}
            </TooltipMenu>
        </VisibleAreasButton>
    )
}

const StyledCheckbox = styled(Checkbox)`
    &.MuiFormControlLabel-root {
        padding: 5px 30px 5px 15px;
    }
`

const VisibleAreasButton = styled.div`
    position: absolute;
    z-index: ${zIndex.medium};
    right: 4px;
    bottom: 45px;
    cursor: pointer;
`
