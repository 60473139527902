import { ins } from 'src/api/axios'
import {
    type GetZoneResponse,
    type LegalEntity,
    type GetLegalEntitiesResponse,
    type PostLegalEntityResponse,
    type PutLegalEntityResponse,
    type Zone,
    type ParkingAssistantSettings,
    type FeatureCollection,
    type CreateNewAreasFeatureCollection,
    type ParkingModeRes,
    type Threshold,
    type AreaType,
    type GetAreasByZoneIdResponse,
} from 'src/api/fm/zones/zones.model'
import { getHeaders } from 'src/api/headers'
import { getError } from 'src/api/utils'
import { AREA_TYPES_INCLUDING_BOUNTY } from 'src/components/zones/zone-details/addArea/static'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/createZone API documentation}
 */
export const createNewZone = async (zone: Zone): Promise<Zone | Error> => {
    const link = encodeURI('v1/fm/zones')
    const headers = await getHeaders()

    return ins
        .post<GetZoneResponse>(link, zone, { headers })
        .then(res => mapGetZoneResponse(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/updateZone API documentation}
 */
export const updateZone = async (zone: Zone): Promise<Zone | Error> => {
    const link = encodeURI(`v1/fm/zones/${zone.zone_id}`)
    const headers = await getHeaders()

    return ins
        .put<GetZoneResponse>(link, zone, { headers })
        .then(res => mapGetZoneResponse(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/patchBatteryThresholds API documentation}
 */
export const updateThresholdsByZoneId = async (
    zoneId: string,
    thresholds: Threshold[],
): Promise<Threshold[] | Error> => {
    const link = encodeURI(`v1/fm/zones/${zoneId}/battery-thresholds`)
    const headers = await getHeaders()

    return ins
        .patch<GetZoneResponse>(link, thresholds, { headers })
        .then(res => res.data.thresholds)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/getAllZones API documentation}
 */
export const getAllZones = async (): Promise<Zone[] | Error> => {
    const link = encodeURI('v1/fm/zones')
    const headers = await getHeaders()

    return ins
        .get<{ data: GetZoneResponse[] }>(link, { headers })
        .then(res => res.data.data.map(zone => mapGetZoneResponse(zone)))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/getZoneByID API documentation}
 */
export const getZoneById = async (zoneId: string): Promise<Zone | Error> => {
    const link = encodeURI(`v1/fm/zones/${zoneId}`)
    const headers = await getHeaders()

    return ins
        .get<GetZoneResponse>(link, { headers })
        .then(res => mapGetZoneResponse(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/getParkingAssistantSettings API documentation}
 */
export const getParkingAssistantSettingsPerZone = async (zoneId: string): Promise<ParkingAssistantSettings | Error> => {
    const link = encodeURI(`/v1/fm/safety-control/zone-settings/${zoneId}`)
    const headers = await getHeaders()

    return ins
        .get(link, { headers })
        .then(res => mapFineAmountToFullCurrency(res.data.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/updateParkingAssistantSettings API documentation}
 */
export const updateParkingAssistantSettingsPerZone = async (
    settings: ParkingAssistantSettings,
): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/safety-control/zone-settings/${settings.zoneId}`)
    const headers = await getHeaders()

    return ins
        .put(link, mapFineAmountToCents(settings), { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/getAreasByZoneIDs API documentation}
 */
export const getAreasByZoneId = async (zoneId: string | number): Promise<FeatureCollection | Error> => {
    const link = encodeURI(`v1/fm/zones/${zoneId}/areas`)
    const headers = await getHeaders()
    return ins
        .get<GetAreasByZoneIdResponse>(link, { headers })
        .then(res => mapGetAreasByZoneId(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/deleteZone API documentation}
 */
export const deleteZoneById = async (id: string | number): Promise<void | Error> => {
    const link = encodeURI(`v1/fm/zones/${id}`)
    const headers = await getHeaders()

    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/deleteArea API documentation}
 */
export const deleteAreaById = async (areaId: string, areaType: string): Promise<void | Error> => {
    const headers = await getHeaders()
    const link = encodeURI(`v1/fm/zones/areas/${areaId}?area_type=${areaType}`)

    return ins
        .delete(link, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/getLegalEntities API documentation}
 */
export async function getLegalEntities(): Promise<LegalEntity[] | Error> {
    const link = encodeURI('v1/fm/zones/legal-entities')
    const headers = await getHeaders()

    return ins
        .get<GetLegalEntitiesResponse>(link, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/createLegalEntity API documentation}
 */
export const createNewLegalEntity = async (legalEntity: LegalEntity): Promise<LegalEntity | Error> => {
    const link = encodeURI('v1/fm/zones/legal-entities')
    const headers = await getHeaders()

    return ins
        .post<PostLegalEntityResponse>(link, legalEntity, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/updateLegalEntity API documentation}
 */
export const editLegalEntity = async (legalEntity: LegalEntity): Promise<LegalEntity | Error> => {
    const link = encodeURI(`v1/fm/zones/legal-entities/${legalEntity.country}`)
    const headers = await getHeaders()

    return ins
        .put<PutLegalEntityResponse>(link, legalEntity, { headers })
        .then(res => res.data.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/createAreas API documentation}
 */
export const createNewAreas = async (
    fc: CreateNewAreasFeatureCollection,
    areaType: AreaType,
): Promise<FeatureCollection | Error> => {
    const link = encodeURI(`v1/fm/zones/areas?area_type=${areaType}`)
    const headers = await getHeaders()

    return ins
        .post(link, fc, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/zone%20gateway/updateAreaByID API documentation}
 */
export const editArea = async (
    fc: FeatureCollection,
    id: string,
    areaType: string,
): Promise<FeatureCollection | Error> => {
    const headers = await getHeaders()
    const link = encodeURI(`v1/fm/zones/areas/${id}?area_type=${areaType}`)

    return ins
        .put(link, fc, { headers })
        .then(res => res.data)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetZoneResponse = (res: GetZoneResponse): Zone => {
    const {
        thresholds,
        polygon,
        companyId,
        companyName,
        country,
        creditsExchangeRate,
        currency,
        defaultLocale,
        displaySpeedUnit,
        feeOutOfZone,
        isActive,
        isTestZone,
        isSleeping,
        language,
        locales,
        maxSpeed,
        name,
        parkingMode,
        parkingSnapshotMode,
        sleepHours,
        supportedVehicleCategories,
        timeZone,
        vat,
        zoneId,
    } = res

    return {
        thresholds,
        polygon,
        companyId,
        companyName,
        country,
        creditsExchangeRate,
        currency,
        defaultLocale,
        displaySpeedUnit,
        feeOutOfZone,
        isActive,
        isTestZone,
        isSleeping,
        language,
        locales,
        maxSpeed,
        name,
        parkingSnapshotMode,
        supportedVehicleCategories: supportedVehicleCategories ?? [],
        timeZone,
        vat,
        minuteCost: 1, // Remove this when zone#PUT request is migrated to FM gateway
        parkingMode: mapParkingMode(parkingMode),
        sleepHours: sleepHours ?? null,
        zone_id: zoneId,
    }
}

const mapParkingMode = (mode: ParkingModeRes) => {
    if (mode === 'free_floating') {
        return 'free-floating'
    }
    if (mode === 'parking_spot') {
        return 'parking-spot'
    }
    if (mode === 'soft_mpz') {
        return 'soft-mpz'
    }

    return mode
}

const mapFineAmountToCents = (settings: ParkingAssistantSettings): ParkingAssistantSettings => {
    return {
        ...settings,
        badPhotoFineAmount: settings.badPhotoFineAmount * 100,
        badParkingFineAmount: settings.badParkingFineAmount * 100,
        noPhotoFineAmount: settings.noPhotoFineAmount * 100,
        abandonedVehicleFineAmount: settings.abandonedVehicleFineAmount * 100,
    }
}

const mapFineAmountToFullCurrency = (settings: ParkingAssistantSettings): ParkingAssistantSettings => {
    return {
        ...settings,
        badPhotoFineAmount: settings.badPhotoFineAmount / 100,
        badParkingFineAmount: settings.badParkingFineAmount / 100,
        noPhotoFineAmount: settings.noPhotoFineAmount / 100,
        abandonedVehicleFineAmount: settings.abandonedVehicleFineAmount / 100,
    }
}

const mapGetAreasByZoneId = (data: GetAreasByZoneIdResponse): FeatureCollection => {
    return {
        ...data,
        // Only known area types can be properly processed in the presentation layer. Therefore we are removing unknown area types.
        features: data.features.filter(feature => {
            return AREA_TYPES_INCLUDING_BOUNTY.includes(feature.properties.area_type)
        }),
    }
}
