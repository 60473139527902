import { useState } from 'react'
import RidingScooter from 'src/assets/images/riding_scooter.svg'
import styled from '@emotion/styled'

const animationDurationSeconds = 7

export const AnimatedScooter = () => {
    const [runAnimation, setRunAnimation] = useState(false)

    const handleClickIcon = () => {
        if (runAnimation) {
            return
        }

        setRunAnimation(true)

        setTimeout(() => {
            setRunAnimation(false)
        }, animationDurationSeconds * 1000)
    }

    return (
        <Wrapper>
            <ScooterIcon
                src={RidingScooter}
                alt=''
                className={runAnimation ? 'run-animation' : ''}
                onClick={handleClickIcon}
                data-testid='animated-scooter-icon'
            />
        </Wrapper>
    )
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex: 1;
`

const ScooterIcon = styled.img`
    height: 50px;

    &:hover {
        cursor: pointer;
    }

    &.run-animation {
        @keyframes drive-scooter {
            50% {
                transform: translate(150px, 0);
                animation-timing-function: linear;
            }

            50.001% {
                transform: translate(-140px, 0);
                animation-timing-function: ease-out;
            }
        }

        animation-name: drive-scooter;
        animation-duration: ${animationDurationSeconds}s;
        animation-timing-function: ease-in;
    }
`
