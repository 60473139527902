import { getHeaders } from 'src/api/headers'
import { ins } from 'src/api/axios'
import { getError } from 'src/api/utils'
import {
    type GetRiderPaymentDetailsResponse,
    type GetRiderVoiPassResponse,
    type RiderPaymentDetails,
    type RiderVoiPass,
} from 'src/api/fm/payments/payments.model'

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/payments%20gateway/getAppliedRidePlan API documentation}
 */
export const getRiderVoiPass = async (riderId: string): Promise<RiderVoiPass | null | Error> => {
    const link = encodeURI(`/v1/fm/payments/users/${riderId}/applied-ride-plan`)
    const headers = await getHeaders()

    return ins
        .get<GetRiderVoiPassResponse>(link, { headers })
        .then(res => mapGetRiderVoiPass(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/pricing%20gateway/getPaymentDetails API documentation}
 */
export const getRiderPaymentDetails = async (riderId: string): Promise<RiderPaymentDetails | null | Error> => {
    const link = encodeURI(`/v1/fm/payments/users/${riderId}/payment-details`)
    const headers = await getHeaders()

    return ins
        .get<GetRiderPaymentDetailsResponse>(link, { headers })
        .then(res => mapGetRiderPaymentDetails(res.data))
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/payments%20gateway/cancelRidePlan API documentation}
 */
export const expireRiderVoiPass = async (riderId: string, ridePlanId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/payments/users/${riderId}/ride-plans/${ridePlanId}/cancel`)
    const headers = await getHeaders()

    return ins
        .put(link, {}, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/payments%20gateway/abandonContract API documentation}
 */
export const abandonContract = async (riderId: string, contractId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/payments/users/${riderId}/contracts/${contractId}/abandon`)
    const headers = await getHeaders()

    return ins
        .put(link, {}, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/payments%20gateway/refundContract API documentation}
 */
export const cancelAndRefundContract = async (riderId: string, contractId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/payments/users/${riderId}/contracts/${contractId}/refund`)
    const headers = await getHeaders()

    return ins
        .put(link, {}, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

/**
 * Related {@link https://warf.voiapp.io/catalog/fm/api/fm-api/definition#/payments%20gateway/stopContractRenewal API documentation}
 */
export const stopContractRenewal = async (riderId: string, contractId: string): Promise<void | Error> => {
    const link = encodeURI(`/v1/fm/payments/users/${riderId}/contracts/${contractId}/stop-renewal`)
    const headers = await getHeaders()

    return ins
        .put(link, {}, { headers })
        .then(() => undefined)
        .catch(e => getError(e, 'response.data.errors[0]'))
}

const mapGetRiderVoiPass = (response: GetRiderVoiPassResponse): RiderVoiPass | null => {
    if (response.data) {
        return {
            ridePlanId: response.data.ride_plan_id,
            contractId: response.data.contractId,
            name: response.data.name,
            minutesUsed: response.data.minutesUsed,
            zones: response.data.zones,
            active: response.data.active,
            recurrent: response.data.recurrent,
            cancelled: response.data.cancelled,
            renewsOn: response.data.renews_on,
            expiresAt: response.data.expires_at,
        }
    }

    return null
}

const mapGetRiderPaymentDetails = (response: GetRiderPaymentDetailsResponse): RiderPaymentDetails | null => {
    if (response.braintreeCustomerIds) {
        return {
            braintreeCustomerIds: response.braintreeCustomerIds,
        }
    }

    return null
}
