import { pick } from 'lodash-es'
import { type FormEvent, useState } from 'react'
import {
    type ExecutionPriority,
    EXECUTION_PRIORITY_OPTIONS,
    EXECUTION_PRIORITY_OPTIONS_WITHOUT_LOW,
    type TaskType,
    type TaskVehicle,
    type Task,
    EXECUTION_PRIORITY_LABELS,
} from 'src/api/fm/tasks/tasks.model'
import { permissions } from 'src/components/app/accessControl.permissions'
import { checkIfRolesHavePermission } from 'src/components/app/utils/accessControl/accessControlUtils'
import { TaskFormContainer } from 'src/components/parts/active-tasks/createTaskForm/CreateTaskForm'
import {
    noAssigneeTasks,
    requireDescriptionTasks,
    TASK_DESCRIPTION_MAX_LENGTH,
    TASK_TYPE_OPTIONS,
} from 'src/components/parts/active-tasks/createTaskForm/task.types'
import { useAppSelector } from 'src/redux/app/app.hooks'
import { selectCurrentUserRoles } from 'src/redux/auth'
import { selectOrganizationById } from 'src/redux/hunter'
import { selectPermissionsForRoles } from 'src/redux/permissionsForRoles'
import { Button } from 'src/ui-kit/button/Button'
import { Input } from 'src/ui-kit/forms/Forms'
import { Popup } from 'src/ui-kit/popup/Popup'
import { VoiSelect } from 'src/ui-kit/voiSelect/VoiSelect'
import styled from '@emotion/styled'

export interface EditedTaskPayload {
    id: string
    type: TaskType
    info: string
    infoInternal: string
    email: string | undefined
    ownerId: string
    executionPriority: ExecutionPriority
    vehicle: TaskVehicle
}

interface Props {
    task: Task
    onSubmit: (task: EditedTaskPayload) => void
    close: () => void
}

type EditableField = 'info' | 'infoInternal' | 'email' | 'executionPriority'

export function EditTaskForm(props: Props) {
    const { close, task, onSubmit } = props

    const organization = useAppSelector(state => selectOrganizationById(state, task.ownerId))
    const roles = useAppSelector(selectCurrentUserRoles)
    const permissionsForRoles = useAppSelector(selectPermissionsForRoles)

    const initialTask = {
        ...pick(task, ['id', 'type', 'info', 'infoInternal', 'ownerId', 'vehicle']),
        email: organization?.email,
        executionPriority: task.executionPriority.name,
    } as EditedTaskPayload

    const [editedTask, setEditedTask] = useState(initialTask)
    const editTaskField = (fieldName: EditableField, value: string): void => {
        setEditedTask(prev => ({ ...prev, [fieldName]: value }))
    }
    const shouldDisableEmailInput = noAssigneeTasks.includes(editedTask.type) || !!editedTask.ownerId
    const shouldDisablePriorityInput = !checkIfRolesHavePermission({
        roles,
        allow: permissions.tasks_priority_editor,
        permissionsForRoles,
    })

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        onSubmit(editedTask)
    }

    const priorityOptions =
        task.executionPriority.name === 'low' ? EXECUTION_PRIORITY_OPTIONS : EXECUTION_PRIORITY_OPTIONS_WITHOUT_LOW

    return (
        <Popup title='Edit task' close={close}>
            <TaskFormContainer onSubmit={handleSubmit}>
                <VoiSelectStyled
                    disabled
                    title='Task type'
                    value={editedTask.type}
                    options={[]}
                    onChange={() => {}}
                    getOptionLabel={key => TASK_TYPE_OPTIONS[key]}
                    required
                    dataTestId='edit-task-type-select'
                />
                <VoiSelectStyled
                    required
                    disabled={shouldDisablePriorityInput}
                    title='Priority'
                    value={editedTask.executionPriority}
                    options={priorityOptions}
                    onChange={(_, value) => setEditedTask({ ...editedTask, executionPriority: value })}
                    getOptionLabel={option => EXECUTION_PRIORITY_LABELS[option]}
                    dataTestId='edit-task-execution-priority-select'
                />

                <Input
                    onChange={e => editTaskField('info', e.target.value)}
                    required={requireDescriptionTasks.includes(editedTask.type)}
                    maxLength={TASK_DESCRIPTION_MAX_LENGTH}
                    value={editedTask.info}
                    label={`Description — Max. ${TASK_DESCRIPTION_MAX_LENGTH} characters. (To be displayed on the vehicle details in the Voi Fleet App.)`}
                    placeholder='Add description...'
                    dataTestId='edit-task-form-input-info'
                />
                <Input
                    onChange={e => editTaskField('infoInternal', e.target.value)}
                    label='Internal note'
                    required={false}
                    value={editedTask.infoInternal}
                    placeholder='Add note...'
                    dataTestId='edit-task-form-input-info-internal'
                />
                <Input
                    disabled={shouldDisableEmailInput}
                    label='Hunter or organization email'
                    value={editedTask.email}
                    type='email'
                    placeholder='email@example.com'
                    onChange={e => editTaskField('email', e.target.value)}
                    dataTestId='edit-task-form-input-email'
                />
                <Button actionType='submit' isStretched dataTestId='edit-task-form-submit'>
                    Submit
                </Button>
            </TaskFormContainer>
        </Popup>
    )
}

const VoiSelectStyled = styled(VoiSelect)`
    margin-bottom: 15px;
` as typeof VoiSelect
