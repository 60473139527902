import { type InputHTMLAttributes } from 'react'
import styled from '@emotion/styled'

// A circular icon button. Renders a html button-tag so you can pass down your
// own props except for the specified ones, for instance `disabled` and `onClick`

type Props = {
    // Path to background image url
    src: string
    // Height and width in a css value, default '24px'
    size?: string
    // Render the icon either as a circle or a square, default 'circle'
    shape?: 'circle' | 'square'
    dataTestId?: string
}

export const IconButton = ({
    src,
    size = '24px',
    shape = 'circle',
    dataTestId,
    ...rest
}: Props & Omit<InputHTMLAttributes<any>, 'size' | 'type'>) => {
    return <IconContainer $src={src} $size={size} $shape={shape} data-testid={dataTestId} {...rest} />
}

const IconContainer = styled.button<{ $shape: string; $src: string; $size: string }>`
    padding: 0;
    margin: 0;
    border: 0;
    border-radius: ${({ $shape }) => ($shape === 'circle' ? '50%' : '0')};
    cursor: pointer;
    background-color: transparent;
    background-image: ${({ $src }) => `url("${$src}")`};
    background-size: ${({ $size }) => `${$size} ${$size}`};
    height: ${({ $size }) => $size};
    width: ${({ $size }) => $size};

    &:hover {
        opacity: 0.8;
    }

    &:focus {
        outline: none;
    }
`
