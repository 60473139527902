import { Tooltip, type TooltipProps } from '@mui/material'
import * as colors from 'src/ui-kit/colors/colors'

export const TooltipDark = (props: TooltipProps) => (
    <Tooltip
        {...props}
        slotProps={{
            popper: {
                sx: {
                    '& .MuiTooltip-tooltip': {
                        fontSize: 11,
                        maxWidth: '190px',
                        padding: '10px 5px',
                        textAlign: 'center',
                        backgroundColor: colors.black,
                        color: colors.white,
                    },
                    '& .MuiTooltip-arrow': {
                        color: colors.black,
                    },
                },
            },
        }}
    />
)
