import { useState, type ChangeEvent } from 'react'
import styled from '@emotion/styled'
import { Button } from 'src/ui-kit/button/Button'
import { Popup } from 'src/ui-kit/popup/Popup'
import { Input, RadioButton } from 'src/ui-kit/forms/Forms'
import { VoiSelect } from 'src/ui-kit/voiSelect/VoiSelect'
import { P } from 'src/ui-kit/text/Text'

const predefinedReasons = [
    'Task pending longer than expected',
    'By request from the person/org that collected the task',
]

type ReasonType = 'predefined' | 'custom'

interface Props {
    select: (reason: string) => void
    cancel: () => void
}

export const TaskDeclineReasonModal = ({ select, cancel }: Props) => {
    const [selectedPredefinedReason, setSelectedPredefinedReason] = useState('')
    const [selectedCustomReason, setSelectedCustomReason] = useState('')
    const [activeReasonType, setActiveReasonType] = useState<ReasonType>('predefined')

    const getSelectedReasonForActiveType = () => {
        if (activeReasonType === 'predefined') {
            return selectedPredefinedReason
        }

        return selectedCustomReason
    }

    const isSubmitButtonDisabled = getSelectedReasonForActiveType().trim() === ''

    const handleSubmit = () => {
        select(getSelectedReasonForActiveType())
    }

    const handleChangeCustomReason = (e: ChangeEvent<HTMLInputElement>) => {
        setSelectedCustomReason((e.target as HTMLInputElement).value)
    }

    const handleChangeReasonType = (type: ReasonType) => {
        setActiveReasonType(type)

        if (type === 'predefined') {
            setSelectedCustomReason('')
        } else {
            setSelectedPredefinedReason('')
        }
    }

    return (
        <Popup close={cancel} title={'Decline reason'} maxWidth={'700px'} dataTestId={'task-decline-reason-modal'}>
            <P style={{ marginBottom: '30px' }}>Please provide a reason for declining the task(s).</P>
            <Row>
                <RadioButtonWrapper>
                    <RadioButton
                        dataTestId='task-decline-reason-modal-predefined-radio-button'
                        onClick={() => handleChangeReasonType('predefined')}
                        checked={activeReasonType === 'predefined'}
                        label={'Predefined'}
                    />
                </RadioButtonWrapper>
                <InputWrapper>
                    <VoiSelect
                        dataTestId='task-decline-reason-modal-predefined-select'
                        onChange={(_, value) => setSelectedPredefinedReason(value)}
                        value={selectedPredefinedReason || null}
                        name='type'
                        selectStyle={{ maxWidth: '100%' }}
                        options={predefinedReasons}
                        disabled={activeReasonType === 'custom'}
                    />
                </InputWrapper>
            </Row>
            <Row>
                <RadioButtonWrapper>
                    <RadioButton
                        dataTestId='task-decline-reason-modal-custom-radio-button'
                        onClick={() => handleChangeReasonType('custom')}
                        checked={activeReasonType === 'custom'}
                        label={'Custom'}
                    />
                </RadioButtonWrapper>
                <InputWrapper>
                    <Input
                        dataTestId='task-decline-reason-modal-custom-input'
                        onChange={handleChangeCustomReason}
                        value={selectedCustomReason}
                        marginBottom='0'
                        maxWidth='100%'
                        disabled={activeReasonType === 'predefined'}
                    />
                </InputWrapper>
            </Row>
            <SubmitButton
                dataTestId={'task-decline-reason-modal-submit-button'}
                onClick={handleSubmit}
                isStretched
                isDisabled={isSubmitButtonDisabled}
            >
                Submit
            </SubmitButton>
        </Popup>
    )
}

const Row = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 15px;
`

const InputWrapper = styled.div`
    flex: 1;
`

const SubmitButton = styled(Button)`
    margin-top: 20px;
`

const RadioButtonWrapper = styled.div`
    width: 30%;
`
