import styled from '@emotion/styled'
import * as colors from 'src/ui-kit/colors/colors'

export enum StatusIndicatorColor {
    ERROR = 'error',
    WARNING = 'warning',
    SUCCESS = 'success',
}

export const StatusIndicator = styled.div<{ $marginLeft?: string; $color: StatusIndicatorColor }>`
    flex: 0 0 auto;

    /* Makes the indicator size 18px with a left margin 8px */
    margin: 4px 4px 4px ${({ $marginLeft = '12px' }) => $marginLeft};
    width: 10px;
    height: 10px;
    border-radius: 50%;
    ${props => {
        const { $color } = props

        if ($color === StatusIndicatorColor.ERROR) {
            return `background-color: ${colors.red}`
        }

        if ($color === StatusIndicatorColor.WARNING) {
            return `background-color: ${colors.statusYellow}`
        }

        if ($color === StatusIndicatorColor.SUCCESS) {
            return `background-color: ${colors.green}`
        }

        return undefined
    }};
`
